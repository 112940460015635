const { ajax } = require("jquery");
const comunes = require("./comunes.js");

var Webinar = Webinar || {};
Webinar.Home = Webinar.Home || {};

Webinar.Home = (function () {
    "use strict";
    let HomeLoad = function () {
        const CONTROLLER = "home";
        var moment = require("moment");
        moment.locale("es");

        this.Initialize = function () {
            iniTableWebinar();
            newWebinar();
        };

        let editWebinar = function () {
            $("#tblWebinar").off("click", ".editWebinar");
            $("#tblWebinar").on("click", ".editWebinar", function () {
                let idWebinar = $(this).attr("data-id");
                formWebinar(idWebinar);
            });
        };

        let newWebinar = function () {
            $("#btnAddWebinar").click(function () {
                formWebinar(0);
            });
        };

        let formWebinar = function (idWebinar) {
            $.ajax({
                url: `${CONTROLLER}/edit-webinar`,
                data: { id: idWebinar },
            })
                .done(function (response) {
                    $("#mdlBodyWebinar").html("");
                    $("#mdlBodyWebinar").html(response);
                    $("#mdlWebinar").modal("show");
                })
                .fail(function (jqXHR, textStatus, errorThrown) {
                    $.alert({
                        title: "Ocurrio un error inesperado",
                        content: jqXHR.responseJSON.message,
                        type: "orange",
                    });
                })
                .always(function () {
                    if (idWebinar != 0 && $("#imgPortada").attr("src") != "") {
                        $("#flFotoWebinar").removeAttr("required");
                    }
                    addWebinar();
                });
        };

        let addWebinar = function () {
            $("#frmWebinar").submit(function (event) {
                event.preventDefault();
                let frmAddWebinar = $(this);
                let ruta = frmAddWebinar.attr("action");
                let type = frmAddWebinar.attr("method");
                let request = new FormData(
                    document.getElementById(frmAddWebinar.attr("id"))
                );
                $.ajax({
                    url: `${ruta}`,
                    data: request,
                    type: type,
                    processData: false,
                    contentType: false,
                })
                    .done(function (response) {
                        console.log(response);
                        $("#mdlWebinar").modal("hide");
                        frmAddWebinar[0].reset();
                        if (response.success) {
                            $.alert({
                                title: "Solicitud completada",
                                content: "Webinar almacenado correctamente",
                                type: "green",
                            });
                        } else if (
                            response.success == false &&
                            response.message != ""
                        ) {
                            $.alert({
                                title: "Ocurrio un error inesperado",
                                content: response.message,
                                type: "orange",
                            });
                        } else {
                            $.alert({
                                title: "Ocurrio un error inesperado",
                                content: "Intente más tarde",
                                type: "orange",
                            });
                        }
                        iniTableWebinar();
                    })
                    .fail(function (jqXHR, textStatus, errorThrown) {
                        console.log(jqXHR);
                        console.log(textStatus);
                        console.log(errorThrown);
                        $.alert({
                            title: "Ocurrio un error inesperado",
                            content: jqXHR.responseJSON.message,
                            type: "orange",
                        });
                    });
            });
        };

        let iniSearchTableWebinar = function () {
            $("#txtNombre").keyup(function () {
                $("#tblWebinar")
                    .DataTable()
                    .columns(0)
                    .search($(this).val())
                    .draw();
            });

            $("#txtPonente").keyup(function () {
                $("#tblWebinar")
                    .DataTable()
                    .columns(1)
                    .search($(this).val())
                    .draw();
            });

            $("#txtFecha").keyup(function () {
                $("#tblWebinar")
                    .DataTable()
                    .columns(2)
                    .search($(this).val())
                    .draw();
            });
        };

        let iniTableWebinar = function () {
            $("#tblWebinar").DataTable().destroy();
            $("#tblWebinar").DataTable({
                searching: true,
                ordering: false,
                info: false,
                dom: "t<'bottom'ip>",
                language: comunes.languageDataTable(),
                ajax: {
                    url: `${CONTROLLER}/all-webinar`,
                    type: "GET",
                    datatype: "json",
                },
                columns: [
                    {
                        data: "nombre",
                    },
                    {
                        data: "ponente",
                    },
                    {
                        data: "fecha",
                    },
                    {
                        data: "Portada",
                        render: function (data, type, row, meta) {
                            let src = row.imagen.includes("images/")
                                ? row.imagen.substring(
                                      row.imagen.lastIndexOf("images")
                                  )
                                : row.imagen;
                            return (
                                '<img src="' +
                                row.imagen +
                                '" class="img-thumbnail" alt="Portada">'
                            );
                        },
                    },                    
                ],
                createdRow: function (row, data, dataIndex) {                    
                    $(row).addClass("editWebinar");
                    $(row).attr("data-id",data.id);
                },
                initComplete: function (settings, json) {
                    editWebinar();
                    iniSearchTableWebinar();
                },
            });
        };       
    };

    return new HomeLoad();
})();
(function ($, window, document) {
    "use strict";
    $(function () {
        Webinar.Home.Initialize();
    });
})(window.jQuery, window, document);
