const { ajax } = require("jquery");
const comunes = require("./comunes.js");

var Webinar = Webinar || {};
Webinar.Telemercadeo = Webinar.Telemercadeo || {};

Webinar.Telemercadeo = (function () {
    "use strict";
    let TelemercadeoLoad = function () {
        const CONTROLLER = (window.location.href.includes('uvm')) ? "telemercadeouvm": "telemercadeo";

        this.Initialize = function () {
            importCsv();
            comunes.validarCsv();
            iniTablaEnviados();
        };

        let iniTablaEnviados = function(){
            $("#tblRegistrosEnviados").DataTable().destroy();
            $("#tblRegistrosEnviados").DataTable({
                searching: true,
                ordering: false,
                info: false,
                dom: "t<'bottom'ip>",
                language: comunes.languageDataTable(),
                ajax: {
                    url: `/${CONTROLLER}/registros-enviados`,
                    type: "GET",
                    datatype: "json",                    
                },
                columns: [
                    {
                        data: "fechaCarga",
                    },
                    {
                        data: "archivo",
                    },
                    {
                        data: "total",
                    },                
                    {
                        data: "enviados",
                    },
                    {                        
                        render: function (data, type, row, meta) {                            
                            return `<a class="text-center d-block descargar" href='/${CONTROLLER}/exportar?idCsv=${row.id}'><i class="bi bi-download"></i></a>`;
                        },
                    },    
                ],
                createdRow: function (row, data, dataIndex) {                                        
                },
                initComplete: function (settings, json) {
                    
                },
            });
        }

        let importCsv = function () {
            $("#frmImportCsv").submit(function (event) {
                event.preventDefault();
                $("#importFields").addClass("d-none");
                $("#alertCsvInvalidos").addClass("d-none");
                $("#mdlCargandoArchivo").modal("show");                
                let frmAddWebinar = $(this);
                let ruta = frmAddWebinar.attr("action");
                let type = frmAddWebinar.attr("method");
                let request = new FormData(
                    document.getElementById(frmAddWebinar.attr("id"))
                );
                $.ajax({
                    url: `${ruta}`,
                    data: request,
                    type: type,
                    processData: false,
                    contentType: false,
                })
                .done(function (response) {                      
                    let titulo = (response.done) ? "Archivo procesado": "Ocurrio un error inesperado";
                    let tipo = (response.done) ? "green": "orange";
                    let mensaje = response.mensaje;
                    $.confirm({
                        title: titulo,
                        content: mensaje,
                        type: tipo,
                        buttons: {
                            close:{
                                text: 'Ok',
                                action: function(){
                                    $("#mdlCargandoArchivo").modal("hide");
                                    if(response.done){
                                        iniTablaContactosValidos(response.id);                                        
                                    }
                                }
                            }
                        }
                    });
                })
                .fail(function (jqXHR, textStatus, errorThrown) {                      
                    $.confirm({
                        title: "Ocurrio un error inesperado",
                        content: textStatus,
                        type: "red",
                        buttons: {
                            close:{
                                text: 'Ok',
                                action: function(){$("#mdlCargandoArchivo").modal("hide");}
                            }
                        }
                    });
                });
            });
        };        

        let iniTablaContactosValidos = function(idCsvCalixta){       
            $("#importFields").removeClass("d-none");
            $("#tblContactosValidos").DataTable().destroy();
            $("#tblContactosValidos").DataTable({
                searching: true,
                ordering: false,
                info: false,
                dom: "t<'bottom'ip>",
                language: comunes.languageDataTable(),
                ajax: {
                    url: `/${CONTROLLER}/contactos-sin-procesar`,
                    type: "GET",
                    datatype: "json",
                    data :{idCsvCalixta:idCsvCalixta},
                    dataSrc:function(data){                                           
                        if(data.data.length>0){
                            let registrosInvalidos = data.data.filter((item)=>{ return item.valido == 0 }); 
                            iniTablaContactosInvalidos(registrosInvalidos);
                            let registrosValidos = data.data.filter((item)=>{ return item.valido == 1 });
                            if(window.location.href.includes('uvm')){
                                $(".txtIdCsvCalixta").val(data.data[0].id_csv_calixta_uvm);
                            }else{
                                $(".txtIdCsvCalixta").val(data.data[0].id_csv_calixta);
                            }
                            if(registrosInvalidos.length > 0 ){
                                $("#alertCsvInvalidos").removeClass("d-none");
                                $("#totalCsvInvalidos").text(registrosInvalidos.length);                            
                            }
                            return registrosValidos;
                        }else{
                            return new Array();
                        }                     
                    }
                },
                columns: [
                    {
                        data: "nombre",
                    },
                    {
                        data: "telefono",
                    }, 
                    {
                        data: "canal",
                    },
                    {
                        data: "plantilla",
                        render: function (data, type, row, meta) {
                            return '<span>'+data.split(":")[1]+'</span>';
                        },
                    },                   
                ],
                createdRow: function (row, data, dataIndex) {                                        
                },
                initComplete: function (settings, json) {
                    mostrarInvalidos();
                    confirmarEliminarRegistros();
                    confirmarComenzarEnvio(); 
                },
            });
        }

        let mostrarInvalidos = function(){
            $("#lnkMostrarInvalidos").click(function(){
                $("#tblContactosInvalidos").css("width","100%")
                $("#mdlontactosInvalidos").modal("show");
            })            
        }

        let iniTablaContactosInvalidos = function(data){                   
            $("#tblContactosInvalidos").DataTable().destroy();
            $("#tblContactosInvalidos").DataTable({
                searching: true,
                responsive: true,
                ordering: false,
                info: false,
                dom: "t<'bottom'ip>",
                language: comunes.languageDataTable(),
                data : data,            
                columns: [
                    {
                        data: "nombre",
                    },
                    {
                        data: "telefono",
                    },                   
                ],
                createdRow: function (row, data, dataIndex) {                                        
                },
                initComplete: function (settings, json) {
                    
                },
            });
        }

        let confirmarEliminarRegistros = function(){
            $("#btnRecargar").click(function(){
                $.confirm({
                    title: 'Eliminar registros',
                    content: '¿Estás seguro de eliminar los registros?',
                    buttons: {                        
                        eliminar: {
                            text: 'Eliminar',
                            btnClass: 'btn-red',                            
                            action: function(){
                                eliminarRegistros()                                                                
                            }
                        },cancel:{
                            text: 'Cancelar',
                        }                        
                    }
                });
            })
        }

        let eliminarRegistros = function(){                            
            let request = new FormData(
                document.getElementById("frmEliminarRegistros")
            );
            $.ajax({
                url: `/${CONTROLLER}/eliminar-registros`,
                data: request,
                type: "POST",
                processData: false,
                contentType: false
            })
            .done(function (response) {                                                                           
                let titulo = (response.done) ? "Registros eliminados": "Ocurrio un error inesperado";
                let tipo = (response.done) ? "green": "orange";
                let mensaje = response.mensaje;
                $.confirm({
                    title: titulo,
                    content: mensaje,
                    type: tipo,
                    buttons: {
                        close:{
                            text: 'Ok',
                            action: function(){
                                window.location.reload();                                
                            }
                        }
                    }
                });
            })
            .fail(function (jqXHR, textStatus, errorThrown) {                    
                $.confirm({
                    title: "Ocurrio un error inesperado",
                    content: jqXHR.responseJSON.mensaje,
                    type: "red",
                    buttons: {
                        close:{
                            text: 'Ok',
                            action: function(){$("#mdlCargandoArchivo").modal("hide");}
                        }
                    }
                });
            });
        }        

        let confirmarComenzarEnvio = function(){
            $("#btnComenzarEnvio").click(function(){
                $.confirm({
                    title: 'Comenzar envió',
                    content: '¿Deseas comenzar el envió?',
                    buttons: {                        
                        enviar: {
                            text: 'Enviar',
                            btnClass: 'btn-green',                            
                            action: function(){
                                enviarRegistros();
                            }
                        },cancel:{
                            text: 'Cancelar',
                        }                        
                    }
                });
            })
        }

        let enviarRegistros = function(){
            let form = $("#frmEnviarRegistros");
            let ruta = form.attr("action");
            let type = form.attr("method");
            let request = new FormData(
                document.getElementById("frmEnviarRegistros")
            );
            $.ajax({
                url: ruta,
                data: request,
                type: type,
                processData: false,
                contentType: false
            })
            .done(function (response) {                                                                           
                let titulo = (response.done) ? "Registros enviados": "Ocurrio un error inesperado";
                let tipo = (response.done) ? "green": "orange";
                let mensaje = response.mensaje;
                $.confirm({
                    title: titulo,
                    content: mensaje,
                    type: tipo,
                    buttons: {
                        close:{
                            text: 'Ok',
                            action: function(){
                                window.location.reload();                                
                            }
                        }
                    }
                });
            })
            .fail(function (jqXHR, textStatus, errorThrown) {                    
                $.confirm({
                    title: "Ocurrio un error inesperado",
                    content: jqXHR.responseJSON.mensaje,
                    type: "red",
                    buttons: {
                        close:{
                            text: 'Ok',
                            action: function(){$("#mdlCargandoArchivo").modal("hide");}
                        }
                    }
                });
            });
        }

        let enviarContactoPorMinuto = function(contactos){
            let comienzo = 0;            
            $.ajax({
                url: "https://unitec.calixtachat.com/api/v1/chats?api_token=JE1b5d0d7nGlsCJbhOsudMckxaOvI4",
                dataType :"json",
                type: "POST",                
                data: {
                    "template_id": "e5ee59cf_df21_40d2_aaec_195427df401c:retomar_conversacion",
                    "session": "5527683836",
                    "chanel_id" : 22,                        
                    "vars":["Missael"]
                } 
            })
            .done(function (response) {   
                console.log(response)                 
            })
            .fail(function (jqXHR, textStatus, errorThrown) {                    
                console.log(jqXHR)
                console.log(textStatus)
                console.log(errorThrown)
            });            
        }
    };

    return new TelemercadeoLoad();
})();
(function ($, window, document) {
    "use strict";
    $(function () {
        Webinar.Telemercadeo.Initialize();
    });
})(window.jQuery, window, document);
