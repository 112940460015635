const { ajax } = require("jquery");
const comunes = require("./comunes.js");

var Webinar = Webinar || {};
Webinar.Email = Webinar.Email || {};

Webinar.Email = (function () {
    "use strict";
    let EmailLoad = function () {
        const CONTROLLER = "emailcheck";

        this.Initialize = function () {
            comunes.validarCsv();
            importCsv();
            iniTablaHistorial();
        };

        let importCsv = function () {
            $("#frmValidarEmails").submit(function (event) {
                event.preventDefault();
                $("#importFields").addClass("d-none");
                $("#mdlCargandoArchivo").modal("show");
                let frmValidarEmails = $(this);
                let ruta = frmValidarEmails.attr("action");
                let type = frmValidarEmails.attr("method");
                let request = new FormData(
                    document.getElementById(frmValidarEmails.attr("id"))
                );
                $.ajax({
                    url: `${ruta}`,
                    data: request,
                    type: type,
                    processData: false,
                    contentType: false,
                })
                    .done(function (response) {
                        let titulo = response.done
                            ? "Archivo procesado"
                            : "Ocurrio un error inesperado";
                        let tipo = response.done ? "green" : "orange";
                        let mensaje = response.mensaje;
                        $.confirm({
                            title: titulo,
                            content: mensaje,
                            type: tipo,
                            buttons: {
                                close: {
                                    text: "Ok",
                                    action: function () {
                                        $("#mdlCargandoArchivo").modal("hide");
                                        if (response.done) {
                                            iniTablaRegistrosValidos(
                                                response.id
                                            );
                                        }
                                    },
                                },
                            },
                        });
                    })
                    .fail(function (jqXHR, textStatus, errorThrown) {                        
                        $.confirm({
                            title: "Ocurrio un error inesperado",
                            content: textStatus,
                            type: "red",
                            buttons: {
                                close: {
                                    text: "Ok",
                                    action: function () {
                                        $("#mdlCargandoArchivo").modal("hide");
                                    },
                                },
                            },
                        });
                    });
            });
        };

        let iniTablaRegistrosValidos = function (idCsvEmails) {
            $("#importFields").removeClass("d-none");
            $("#tblRegistrosProcesados").DataTable().destroy();
            $("#tblRegistrosProcesados").DataTable({
                searching: true,
                ordering: false,
                info: false,
                dom: "t<'bottom'ip>",
                language: comunes.languageDataTable(),
                ajax: {
                    url: `/${CONTROLLER}/registros-sin-validar`,
                    type: "GET",
                    datatype: "json",
                    data: { idCsvEmails: idCsvEmails },
                    dataSrc: function (data) {           
                        console.log(data)             
                        $(".txtIdCsvEmails").val(data.data[0].id_csv_email);
                        if (data.data.length > 0) {
                            return data.data;
                        } else {
                            return new Array();
                        }
                    },
                },
                columns: [
                    {
                        data: "no_persona",
                    },
                    {
                        data: "contact_id",
                    },
                    {
                        data: "dominio_incorrecto",
                    },
                    {
                        data: "dominio_correcto",
                    },                    
                ],
                createdRow: function (row, data, dataIndex) {
                    if (data.dominio_correcto == "") {
                        $($(row).children()[3]).addClass("d-none");
                        $("#thDominioSugerido").addClass("d-none");
                    }else{
                        $($(row).children()[3]).removeClass("d-none");
                        $("#thDominioSugerido").removeClass("d-none");
                    }
                },
                initComplete: function (settings, json) {
                    confirmarEliminarRegistros();
                    confirmarComenzarValidacion();
                },
            });
        };

        let confirmarEliminarRegistros = function () {
            $("#btnRecargar").click(function () {
                $.confirm({
                    title: "Eliminar registros",
                    content: "¿Estás seguro de eliminar los registros?",
                    buttons: {
                        eliminar: {
                            text: "Eliminar",
                            btnClass: "btn-red",
                            action: function () {
                                eliminarRegistros();
                            },
                        },
                        cancel: {
                            text: "Cancelar",
                        },
                    },
                });
            });
        };

        let eliminarRegistros = function () {            
            let request = new FormData(
                document.getElementById("frmEliminarRegistros")
            );
            $.ajax({
                url: `/${CONTROLLER}/eliminar-registros`,
                data: request,
                type: "POST",
                processData: false,
                contentType: false,
            })
                .done(function (response) {
                    let titulo = response.done
                        ? "Registros eliminados"
                        : "Ocurrio un error inesperado";
                    let tipo = response.done ? "green" : "orange";
                    let mensaje = response.mensaje;
                    $.confirm({
                        title: titulo,
                        content: mensaje,
                        type: tipo,
                        buttons: {
                            close: {
                                text: "Ok",
                                action: function () {
                                    window.location.reload();
                                },
                            },
                        },
                    });
                })
                .fail(function (jqXHR, textStatus, errorThrown) {
                    $.confirm({
                        title: "Ocurrio un error inesperado",
                        content: jqXHR.responseJSON.mensaje,
                        type: "red",
                        buttons: {
                            close: {
                                text: "Ok",
                                action: function () {
                                    $("#mdlCargandoArchivo").modal("hide");
                                },
                            },
                        },
                    });
                });
        };

        let confirmarComenzarValidacion = function(){
            $("#btnComenzarValidacion").click(function(){
                $.confirm({
                    title: 'Validar registros',
                    content: '¿Deseas validar los registros?',
                    buttons: {                        
                        enviar: {
                            text: 'Validar',
                            btnClass: 'btn-green',                            
                            action: function(){
                                validarRegistros();
                            }
                        },cancel:{
                            text: 'Cancelar',
                        }                        
                    }
                });
            })
        }

        let validarRegistros = function(){
            $("#mdlCargandoArchivo").modal("show");
            let form = $("#frmValidarRegistros");
            let ruta = form.attr("action");
            let type = form.attr("method");
            let request = new FormData(
                document.getElementById("frmValidarRegistros")
            );
            $.ajax({
                url: ruta,
                data: request,
                type: type,
                processData: false,
                contentType: false
            })
            .done(function (response) {                                                                                 
                let titulo = (response.done) ? "Registros validados": "Ocurrio un error inesperado";
                let tipo = (response.done) ? "green": "orange";
                let mensaje = response.mensaje;
                $.confirm({
                    title: titulo,
                    content: mensaje,
                    type: tipo,
                    buttons: {
                        close:{
                            text: 'Ok',
                            action: function(){
                                iniTablaRegistrosValidos(response.id);
                                $("#mdlCargandoArchivo").modal("hide");
                                $("#divValidar").addClass("d-none");                        
                                $("#divEliminar").addClass("d-none");                        
                                $("#divDescargar").removeClass("d-none"); 
                                $("#btnDescargarCSV").attr("href",`/${CONTROLLER}/exportar?idCsvEmails=${response.id}`)                      
                            }
                        }
                    }
                });
            })
            .fail(function (jqXHR, textStatus, errorThrown) {                   
                $("#mdlCargandoArchivo").modal("hide");              
                $.confirm({
                    title: "Ocurrio un error inesperado",
                    content: jqXHR.responseJSON.mensaje,
                    type: "red",
                    buttons: {
                        close:{
                            text: 'Ok',
                            action: function(){$("#mdlCargandoArchivo").modal("hide");}
                        }
                    }
                });
            });
        }

        let iniTablaHistorial = function(){
            $("#tblRegistrosEmails").DataTable().destroy();
            $("#tblRegistrosEmails").DataTable({
                searching: true,
                ordering: false,
                info: false,
                dom: "t<'bottom'ip>",
                language: comunes.languageDataTable(),
                ajax: {
                    url: `/${CONTROLLER}/emails-procesados`,
                    type: "GET",
                    datatype: "json",                    
                },
                columns: [
                    {
                        data: "fechaCarga",
                    },
                    {
                        data: "archivo",
                    },
                    {
                        data: "total",
                    },
                    {
                        data: "procesados",
                    },   
                    {                        
                        render: function (data, type, row, meta) {                            
                            return `<a class="text-center d-block descargar" href='/${CONTROLLER}/exportar?idCsvEmails=${row.id}'><i class="bi bi-download"></i></a>`;
                        },
                    },                   
                ],
                createdRow: function (row, data, dataIndex) {   
                    $($(row).children()[3]).addClass("descargar");                       
                },
                initComplete: function (settings, json) {
                    
                },
            });
        }
    };

    return new EmailLoad();
})();
(function ($, window, document) {
    "use strict";
    $(function () {
        Webinar.Email.Initialize();
    });
})(window.jQuery, window, document);
