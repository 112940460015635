exports.languageDataTable = function () {
    return {
        sProcessing: "Procesando...",
        sLengthMenu: "Mostrar _MENU_ registros",
        sZeroRecords: "No se encontraron resultados",
        sEmptyTable: "Ningún dato disponible en esta tabla",
        sInfo: "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
        sInfoEmpty: "Mostrando registros del 0 al 0 de un total de 0 registros",
        sInfoFiltered: "(filtrado de un total de _MAX_ registros)",
        sInfoPostFix: "",
        sSearch: "Buscar:",
        sUrl: "",
        sInfoThousands: ",",
        sLoadingRecords: "Cargando...",
        oPaginate: {
            sFirst: "Primero",
            sLast: "Último",
            sNext: "Siguiente",
            sPrevious: "Anterior",
        },
        oAria: {
            sSortAscending:
                ": Activar para ordenar la columna de manera ascendente",
            sSortDescending:
                ": Activar para ordenar la columna de manera descendente",
        },
        buttons: {
            copy: "Copiar",
            colvis: "Visibilidad",
        },
    };
};

exports.validarCsv = function () {
    $("input[type='file']").change(function () {
        let input = $(this);
        let extensionesPermitidas = input[0].accept;
        let extensionArchivo = input.val();
        extensionArchivo = extensionArchivo.substr(
            extensionArchivo.lastIndexOf(".")
        );
        let pesoArchivo = input[0].files[0].size;
        if (pesoArchivo == 0) {
            $.alert({
                title: "Archivo invalido",
                content: "El peso del archivo debe ser superior a 0.",
                type: "orange",
            });
            input.val("");
        }
        if (!extensionesPermitidas.includes(extensionArchivo)) {
            $.alert({
                title: "Archivo invalido",
                content: "Extensión de archivo no valida.",
                type: "orange",
            });
            input.val("");
        }
    });
};
